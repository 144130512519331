<template>
  <basicData v-if="email !== null" :email="email"></basicData>
</template>

<script>
import basicData from '@/components/emailDetails/basic-data';
import emailService from '@/services/EmailService';

export default {
  components: {
    basicData
  },

  data: () => ({
    email: null
  }),

  async mounted() {
    this.email = await emailService.getEmail(this.$route.params.id);

    this.$route.meta.breadcrumbs.push({
      text: `${this.email.id} - ${this.email.sender} ${this.email.recipient}`
    });
  }
};
</script>
