<template>
  <tiptap-vuetify
    v-model="value"
    :extensions="extensions"
    :placeholder="$t('emailDetails.body')"
    :disabled="disabled"
  ></tiptap-vuetify>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Blockquote,
  HardBreak,
  History,
  Paragraph,
  HorizontalRule,
  Code,
  OrderedList,
  ListItem,
  BulletList
} from 'tiptap-vuetify';

export default {
  components: { TiptapVuetify },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    extensions: [
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      History,
      Bold,
      Italic,
      Blockquote,
      Link,
      Underline,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ]
  }),

  watch: {
    value(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style>
.tiptap-vuetify-editor--disabled .tiptap-vuetify-editor__toolbar {
  display: none;
}
.tiptap-vuetify-editor__content--disabled {
  color: inherit;
}
.tiptap-vuetify-editor--disabled {
  cursor: inherit;
}
</style>
